<script setup>
import { useApp } from "~/composables/useApp";
import { useBible } from "~/composables/useBible";
import { transl } from "~/composables/useTranslation";
import { useSettings } from "~/composables/useSettings";
import { useSelection } from "~/composables/useSelection";
import { useScrollEvent } from "~/composables/useScrollEvent";

const { bibles } = useApp();
const { loading } = useBible();
const { sheets, settingsComputedClass } = useApp();
const { settings } = useSettings();

const props = defineProps(["singleBible", "elementId"]);

const scrollEl = ref(null);
const verseMenu = ref(false);
const verseMenuCoords = ref(null);

const selection = reactive(useSelection());
const selectedVerse = ref(null);
const selectedVerseHtmlElem = ref(null);

function handleCopy(versesModel) {
  if (copyVerses(versesModel)) toast("Copied");
}

function handleSelect({ model: verseModel, el }) {
  selection.turnOn();

  const success = selection.addElem(verseModel);

  if (success) el.classList.add("isSelected");
  else el.classList.remove("isSelected");

  selectedVerse.value = verseModel;
}

function handleShare(verseModel) {
  sheets.shareSheet.text = toSharingText([verseModel]);
  sheets.shareSheet.sheet = true;
}

function handleOpen({ coords, verseModel, el }) {
  selectedVerse.value = verseModel;
  selectedVerseHtmlElem.value = el;
  verseMenuCoords.value = coords;
  verseMenu.value = true;
}

function closeSelectionMode() {
  selection.turnOff();
  selection.clear();
  cleanSelection();
}

function watchScroll() {
  const { setupScroll, onScroll } = useScrollEvent();
  setupScroll(scrollEl.value);

  let isLoading = false; // Флаг загрузки

  onScroll(async (e) => {
    if (isLoading) return; // Проверка флага

    isLoading = true; // Устанавливаем флаг загрузки

    const scroll = scrollEl.value;
    const visibleChapter =
      bibles.singleBible.location?.value.chapterNumIntoView;
    const maxChapters =
      bibles.singleBible.location.value?.bookModel?.bookChapters;
    const scrollY = scroll.scrollTop;
    // const scrollBottomEdge = 200;
    const scrollTopEdge = scroll.scrollHeight - scroll.clientHeight - 200;

    if (scrollY == 0 && visibleChapter > 1) {
      await props.singleBible.showMoreTop({ scrollEl: scrollEl.value });
    }
    if (scrollY > scrollTopEdge && visibleChapter < maxChapters) {
      await props.singleBible.showMoreBottom({ scrollEl: scrollEl.value });
    }

    isLoading = false; // Сбрасываем флаг после завершения
  });
}

function watchProps() {
  // mouse actions
  watch(
    () => props.singleBible.location.value.chaptersHtml,
    () => {
      setTimeout(() => {
        console.log("html changed");
        setupVersesMouseActions({ container: scrollEl.value });
      });
    },
    {
      deep: true,
    }
  );
}

function setupVersesMouseActions({ container }) {
  if (!container) return;

  const versesNodesColl = container.querySelectorAll(".v-bible-verse");
  const versesHtmlColl = Array.from(versesNodesColl);

  versesHtmlColl.map((verseHtmlElem, index) => {
    const versesColl = props.singleBible.location.value.chaptersHtml?.flatMap(
      (chapterModel) => chapterModel.verses
    );
    const verseModel = versesColl.find(
      (verse) => verse._id == verseHtmlElem.getAttribute("_id")
    );

    verseHtmlElem.onclick = (ev) => {
      // selection mode
      if (selection.isEnabled) {
        handleSelect({
          model: verseModel,
          el: verseHtmlElem,
        });
        return;
      }

      // open menu mode
      const coords = {
        x: ev.pageX,
        y: ev.pageY,
      };

      handleOpen({ coords, verseModel, el: verseHtmlElem });
    };
  });
}

function cleanSelection() {
  const versesNodesColl = scrollEl.value.querySelectorAll(".isSelected");
  const versesHtmlColl = Array.from(versesNodesColl);

  versesHtmlColl.map((el) => el.classList.remove("isSelected"));
}

onMounted(() => {
  watchProps();
  watchScroll();

  const { htmlElements } = useApp();

  htmlElements[props.elementId].scrollEl = scrollEl.value;
});
</script>
<template>
  <div
    class="bible-scroll-el px-4"
    :ref="(el) => (scrollEl = el)"
    :key="singleBible.location.value.updateHash"
  >
    <div v-if="loading">
      <v-loader-ring class="mx-auto py-10"></v-loader-ring>
    </div>

    <!-- verses html -->
    <div
      class="verses-wrapper grid-container up"
      :class="{
        ...settingsComputedClass,
        enableStrong: settings.enableStrong,
      }"
    >
      <span
        v-for="chapterHtml in singleBible.location.value.chaptersHtml"
        v-html="chapterHtml.html"
        :key="chapterHtml.chapterNum"
        class="grid-item"
      ></span>
    </div>

    <div v-if="loading">
      <v-loader-ring class="mx-auto py-10"></v-loader-ring>
    </div>

    <div
      class="endofthebook chapter-verse"
      v-if="singleBible.location.value.endOfTheBook"
    >
      {{ transl("End of the book") }}
    </div>

    <v-bible-verse-menu
      v-model:verseMenu="verseMenu"
      :verseModel="selectedVerse"
      :verseMenuCoords="verseMenuCoords"
      @select="
        handleSelect({ model: selectedVerse, el: selectedVerseHtmlElem })
      "
      @copy="handleCopy([selectedVerse])"
      @share="handleShare(selectedVerse)"
    ></v-bible-verse-menu>

    <v-verse-sheet
      :modelValue="selection.isEnabled"
      :items="selection.selectionLength"
      @close="closeSelectionMode()"
      @copy="
        handleCopy(selection.selectionColl);
        closeSelectionMode();
      "
      @share="
        sheets.shareSheet.text = toSharingText(selection.selectionColl);
        sheets.shareSheet.sheet = true;
        closeSelectionMode();
      "
    ></v-verse-sheet>
  </div>
</template>
<style scoped>
.bible-scroll-el {
  height: 100%;
  /* overflow-y: auto; */
  overflow-x: hidden;
  position: relative;
}

.verses-wrapper {
  /* display: flex; */
  /* flex-direction: column; */
  min-height: calc(100vh + 200px);
  box-sizing: inherit;
  padding: 0 80px;
  /* padding-bottom: 900px; */
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr; /* Одна колонка */
  transition: all 0.3s ease; /* Плавный переход при изменении направления */
}

.grid-container.down {
  grid-auto-flow: dense; /* Элементы будут добавляться снизу вверх */
}

.grid-container.up {
  grid-auto-flow: row; /* Элементы будут добавляться сверху вниз */
}

.grid-item {
  /* padding: 20px; */
  /* margin: 5px; */
  /* text-align: center; */
  padding-bottom: 15px;
}

@media (max-width: 600px) {
  .verses-wrapper {
    padding: 0 20px;
  }
}
@media (max-width: 400px) {
  .verses-wrapper {
    padding: 0 10px;
  }
}
@media (max-width: 380px) {
  .verses-wrapper {
    padding: 0px;
  }
}
.endofthebook {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

@media (hover: hover) {
  :global(.chapter-verse:hover) {
    background-color: rgba(246, 246, 246, 0.518);
  }
}
@media (hover: none) {
  :global(.chapter-verse:active) {
    background-color: rgba(246, 246, 246, 0.518);
  }
}

.chapter-verse:active {
  /* background-color: rgb(211, 211, 211); */
}
.v-bible-verse {
  content-visibility: auto;
}
.checkbox-el {
  width: 30px;
  height: 30px;
}

:global(.isSelected) {
  background-color: rgb(220, 220, 220);
  border-radius: 5px;
}
</style>
